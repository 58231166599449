<template>
  <b-card no-body>
    <b-card-body>
      <vue-apex-charts
        type="area"
        height="550"
        :options="lineAreaChartSpline.chartOptions"
        :series="lineAreaChartSpline.series"
      /></b-card-body></b-card>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    optionDataX: {
      type: Array,
      default: null,
    },
    authorize: {
      type: Array,
      default: null,
    },
    soft: {
      type: Array,
      default: null,
    },
    dark: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

      lineAreaChartSpline: {
        series: [
          {
            name: 'Authorize',
            data: this.authorize,
          },
          {
            name: 'Soft',
            data: this.soft,
          },

        ],
        chartOptions: {
          chart: {
            foreColor: '#ccc',
            toolbar: {
              show: false,
            },
          },
          markers: {
            size: 5,
            colors: ['rgba(255, 0,0, 0.5)', 'rgba(0, 231, 255, 0.9)'],
            strokeColor: '#ffffff',
            strokeWidth: 3,
          },
          dataLabels: {
            enabled: false,

          },
          stroke: {
            show: true,
            curve: 'straight',
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'center',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },

          xaxis: {
            type: 'category',
            categories: this.optionDataX,

          },

          yaxis: {
            // opposite: isRtl
          },
          fill: {

            type: 'gradient',
            gradient: {

              opacityFrom: 0.5,
              opacityTo: 0.9,
              stops: [0, 90, 100],
            },
          },
          tooltip: {
            theme: this.dark,
            shared: true,
          },
          colors: ['rgba(255, 0,0, 0.5)', 'rgba(0, 231, 255, 0.9)'],
        },
      },
    }
  },

}
</script>

<style scoped>

</style>
