<template>
  <div>

    <div class="d-flex   justify-content-end mt-2 mr-1 mb-1">
      <div class="mr-1"> <b-button
        variant="success"
        @click="exportToExcel"
      >
        <feather-icon
          icon="DownloadIcon"
          size="16"
          class="mr-1 "
        />Export to Excel

      </b-button></div>
      <div>   <b-button
        variant="info"
        @click="toogleReport"
      >
        <feather-icon
          icon="ActivityIcon"
          size="16"
          class="mr-1"
        />
        {{ isShowReport ? 'Change to graphic' : 'Change to table' }}
      </b-button> </div>
    </div>

    <b-col v-if="isShowReport">
      <b-row class="mt-1 mb-1">
        <b-col
            cols="7"
            class="d-flex align-items-center"
        >
          <b-button

              variant="success"
              :disabled="!isTimerOn"
              @click="refreshData"
          >
            <feather-icon
                v-if="isTimerOn"
                icon="RefreshCwIcon"
                size="16"
                class="mr-1"
                :class="{ 'fa-spin': !isTimerOn }"
            />
            <b-spinner
                v-else
                small
                class="mr-1"
            />
            <span v-if="isTimerOn">REFRESH</span>
            <span v-else>{{ timer }}</span>
          </b-button>
        </b-col>
        <b-col
        >
          <b-row>
            <b-col lg="7" xl="8">
              <b-form-group
                  class="width-w"
                  label="Year"
                  label-for="year"
              >
                <b-form-select
                    id="year"
                    v-model="year"
                    :options="optionYears"
                />
              </b-form-group>
            </b-col>
            <b-col lg="5" xl="4" class="d-flex align-items-center justify-content-end">
              <b-button
                  variant="primary"
                  size="sm"
                  @click="search"
                  style="margin-right: 0.6rem"
              >
                <feather-icon
                    icon="SearchIcon"
                    size="16"
                />
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  @click="clearSearch"
                  style="margin-right: 0.6rem"

              >
                <feather-icon

                    icon="Edit2Icon"
                    size="16"
                />
              </b-button>
              <b-button-group class="float-right">
                <b-button
                    size="sm"
                    :disabled="chunkMonth === 0"
                    variant="info"
                    @click="changeChunkMonth(0)"
                >
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="16"
                  />
                </b-button>
                <b-button
                    size="sm"
                    variant="info"
                    :disabled="chunkMonth === 1"

                    @click="changeChunkMonth(1)"
                >
                  <feather-icon icon="ChevronRightIcon" />
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-row v-if="isShowReport">
      <div
        class="col-lg-12"
        style="width: 100%;white-space: nowrap;display: inline-block;margin: 0 auto;"
      >

        <div>
          <b-table-simple
            ref="table_vs_payment"
            :key="contTale"
            hover
            striped
            responsive
            :busy.sync="isBusy"
          >
            <b-thead>
              <b-tr>
                <b-th

                  :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'"
                  rowspan="2"
                >
                  <div
                    style=" position: absolute;
  top: 6%;
  left: 2.2%;
  font-size: 15px; filter: contrast(100%);"
                  >
                    <span style="color: white!important; opacity: 3!important;"> PROGRAMS</span>

                  </div>
                </b-th>
                <b-th
                  v-if="chunkMonth === 0"
                  :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'"

                  colspan="2"
                >
                  <span
                    style="color: white!important; opacity: 3!important;"
                    class="d-flex justify-content-center"
                  >JAN</span>
                </b-th>
                <b-th
                  v-if="chunkMonth === 0"
                  :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'"

                  colspan="2"
                >
                  <span
                    style="color: white!important; opacity: 3!important;"
                    class="d-flex justify-content-center"
                  >FEB</span>
                </b-th>
                <b-th
                  v-if="chunkMonth === 0"
                  :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'"

                  colspan="2"
                >
                  <span
                    style="color: white!important; opacity: 3!important;"
                    class="d-flex justify-content-center"
                  >MAR</span>
                </b-th>
                <b-th
                  v-if="chunkMonth === 0"
                  :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'"

                  colspan="2"
                >
                  <span
                    style="color: white!important; opacity: 3!important;"
                    class="d-flex justify-content-center"
                  >APR</span>
                </b-th>
                <b-th
                  v-if="chunkMonth === 0"
                  :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'"

                  colspan="2"
                >
                  <span
                    style="color: white!important; opacity: 3!important;"
                    class="d-flex justify-content-center"
                  >MAY</span>
                </b-th>
                <b-th
                  v-if="chunkMonth === 0"
                  :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'"

                  colspan="2"
                >
                  <span
                    style="color: white!important; opacity: 3!important;"
                    class="d-flex justify-content-center"
                  >JUN</span>
                </b-th>
                <b-th
                  v-if="chunkMonth === 1"
                  :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'"

                  colspan="2"
                >
                  <span
                    style="color: white!important; opacity: 3!important;"
                    class="d-flex justify-content-center"
                  >JUL</span>
                </b-th>
                <b-th
                  v-if="chunkMonth === 1"
                  :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'"

                  colspan="2"
                >
                  <span
                    style="color: white!important; opacity: 3!important;"
                    class="d-flex justify-content-center"
                  >AUG</span>
                </b-th>
                <b-th
                  v-if="chunkMonth === 1"
                  :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'"

                  colspan="2"
                >
                  <span
                    style="color: white!important; opacity: 3!important;"
                    class="d-flex justify-content-center"
                  >SEP</span>
                </b-th>
                <b-th
                  v-if="chunkMonth === 1"
                  :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'"

                  colspan="2"
                >
                  <span
                    style="color: white!important; opacity: 3!important;"
                    class="d-flex justify-content-center"
                  >OCT</span>
                </b-th>
                <b-th
                  v-if="chunkMonth === 1"
                  :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'"

                  colspan="2"
                >
                  <span
                    style="color: white!important; opacity: 3!important;"
                    class="d-flex justify-content-center"
                  >NOV</span>
                </b-th>
                <b-th
                  v-if="chunkMonth === 1"
                  :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'"

                  colspan="2"
                >
                  <span
                    style="color: white!important; opacity: 3!important;"
                    class="d-flex justify-content-center"
                  >DEC</span>
                </b-th>
                <b-th
                  v-if="chunkMonth === 1"
                  class="color_total"
                  colspan="2"
                >
                  <span
                    style="color: white!important;"
                    class="d-flex justify-content-center"
                  >TOTAL</span>
                </b-th>
              </b-tr>
              <b-tr class="th-bg">
                <b-th

                  class="color_midle"
                >
                  AUTHORIZE
                </b-th>
                <b-th class="color_midle">
                  SOFT
                </b-th>
                <b-th class="color_midle">
                  AUTHORIZE
                </b-th>
                <b-th class="color_midle">
                  SOFT
                </b-th>
                <b-th class="color_midle">
                  AUTHORIZE
                </b-th>
                <b-th class="color_midle">
                  SOFT
                </b-th>
                <b-th class="color_midle">
                  AUTHORIZE
                </b-th>
                <b-th class="color_midle">
                  SOFT
                </b-th>
                <b-th class="color_midle">
                  AUTHORIZE
                </b-th>
                <b-th class="color_midle">
                  SOFT
                </b-th>
                <b-th class="color_midle">
                  AUTHORIZE
                </b-th>
                <b-th class="color_midle">
                  SOFT
                </b-th>
                <b-th
                  v-if="chunkMonth === 1"
                  :style="isDarkSkin? 'background: #000 !important; opacity: 0.4!important;':'background: #000 !important; opacity: 3!important;'"
                >
                  <span style="color: white!important;">AUTHORIZE</span>
                </b-th>
                <b-th
                  v-if="chunkMonth === 1"
                  :style="isDarkSkin? 'background: #000 !important; opacity: 0.4!important;':'background: #000 !important; opacity: 3!important;'"
                >
                  <span style="color: white!important;">SOFT</span>
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(item, index) in payments"
                :key="index"
              >
                <td>{{ item.program_name }}</td>
                <template v-for="(month, i) in item.payment">
                  <td
                    v-if="month.chunk === chunkMonth"
                    :key="i"
                  >
                    <span> ${{ formatPrice(month.authorized) }}
                    </span>

                  </td>
                  <td
                    v-if="month.chunk === chunkMonth"
                    :key="`${i}_autho`"
                  >
                    <span> ${{ formatPrice(month.soft) }}
                    </span>

                  </td>
                </template>
                <template v-if="chunkMonth === 1">
                  <td :style="isDarkSkin? 'background: #333 !important; opacity: 5!important;':'background: #333 !important; opacity: 0.7!important;'">
                    <span style="color: white!important;"> ${{ formatPrice(item.payments_authorized) }}
                    </span>

                  </td>
                  <td :style="isDarkSkin? 'background: #333 !important; opacity: 5!important;':'background: #333 !important; opacity: 0.7!important;'">
                    <span style="color: white!important;"> ${{ formatPrice(item.payments_soft) }}
                    </span>

                  </td>
                </template>
              </b-tr>
              <b-tr v-if="payments.length > 0">
                <td :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'">
                  <span style="color: white!important; opacity: 3!important;">TOTAL</span>
                </td>
                <template v-for="(month, index) in total_authorized">
                  <td
                    v-if="chunkMonth === total_authorized[index].chunk"
                    :key="`${index}_total`"
                    :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'"
                  >
                    <span style="color: white!important; opacity: 3!important;">  ${{ formatPrice(total_authorized[index].amount) }}
                    </span>

                  </td>
                  <td
                    v-if="chunkMonth === total_soft[index].chunk"
                    :key="index"
                    :style="isDarkSkin? 'background: #636869 !important; opacity: 0.7!important;':'background: #636869 !important; opacity: 5!important;'"
                  >
                    <span style="color: white!important; opacity: 3!important;"> ${{ formatPrice(total_soft[index].amount) }}</span>

                  </td>
                </template>

                <template v-if="chunkMonth === 1">
                  <td class="color_total">
                    <span style="color: white!important;"> ${{ formatPrice(sumPaymentsAutorized) }}
                    </span>
                  </td>
                  <td class="color_total">
                    <span style="color: white!important;"> ${{ formatPrice(sumPaymentsSoft) }}
                    </span>

                  </td>
                </template>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </b-row>
    <div v-else>
      <b-row>
        <b-col
          lg="6"
          offset="6"
        >
          <b-row>
            <b-col lg="5">
              <b-form-group
                label="Programs"
                label-for="program"
              >
                <b-form-select
                  id="program"
                  v-model="program"
                  :options="optionPrograms"
                />
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                label="Year"
                label-for="year"
              >
                <b-form-select
                  id="year"
                  v-model="yearGraphic"
                  :options="optionYearsGraphic"
                />
              </b-form-group>
            </b-col>
            <b-col
              lg="1"
              class="d-flex align-items-center"
            >
              <b-button
                variant="primary"
                size="sm"
                @click="getValuesGraphic"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="16"
                />
              </b-button>
            </b-col>
            <b-col
              lg="1"
              class="d-flex align-items-center"
            >
              <b-button
                variant="primary"
                size="sm"
                @click="clearSearchGraphic"
              >
                <feather-icon

                  icon="Edit2Icon"
                  size="16"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <vs-payment-chart
        :key="contChart"
        :authorize="authorize"
        :soft="soft"
        :dark="dark"
        :option-data-x="optionDataX"
      />
    </div>

  </div>
</template>
<script>
import moment from 'moment'

import ReportsService from '@/views/commons/components/reports/reports.service'
import GlobalService from '@/views/services/global.service'

import VsPaymentChart from '@/views/commons/components/reports/tabs/vs-payment/VsPaymentChart.vue'

export default {
  components: {

    VsPaymentChart,

  },
  data() {
    return {
      optionDataX: [],
      contChart: 1,
      year: moment().format('YYYY'),
      yearGraphic: moment().format('YYYY'),
      isTimerOn: true,
      isLoading: true,
      isShowReport: true,
      timer: moment(10 * 1 * 1000).format('mm:ss'),
      payments: [],
      total_authorized: [],
      total_soft: [],
      soft: [],
      contTale: 1,
      authorize: [],
      chunkMonth: 0,
      bindMoney: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: false,
      },
      optionPrograms: [
        {
          text: 'Select Program',
          value: null,
          disabled: true,
        },
      ],
      isBusy: false,
      program: null,
      dark: '',
    }
  },
  computed: {
    optionYears() {
      const years = []
      for (let i = 2019; i <= moment().format('YYYY'); i++) {
        years.push({ text: i, value: i })
      }
      return years
    },
    optionYearsGraphic() {
      const years = []
      for (let i = 2019; i <= moment().format('YYYY'); i++) {
        years.push({ text: i, value: i })
      }
      return years
    },
    sumPaymentsAutorized: {
      get() {
        if (this.payments.length > 0) {
          return this.payments.reduce(
            (accumulator, current) => accumulator + Number(current.payments_authorized),
            0,
          )
        }
        return 0
      },
      set(val) {
        return val
      },
    },
    sumPaymentsSoft: {
      get() {
        if (this.payments.length > 0) {
          return this.payments.reduce(
            (accumulator, current) => accumulator + Number(current.payments_soft),
            0,
          )
        }
        return 0
      },
      set(val) {
        return val
      },
    },
  },
  watch: {
    isDarkSkin(newValue) {
      if (newValue === true) {
        this.dark = 'dark'
        this.contChart++
      } else {
        this.dark = 'light'
        this.contChart++
      }
    },
  },
  async created() {
    this.addPreloader()
    await this.search()
    if (this.isDarkSkin === true) {
      this.dark = 'dark'
    } else {
      this.dark = 'light'
    }
    await this.getValuesGraphic()
    this.removePreloader()
  },
  async mounted() {
    if (moment().format('MM') >= 7) {
      this.chunkMonth = 1
    }
    await this.getPrograms()
  },
  methods: {
    async  clearSearch() {
      this.program = null
      this.year = moment().format('YYYY')
      await this.search()
    },
    changeChunkMonth(chunk) {
      this.chunkMonth = chunk
    },
    async getValuesGraphic() {
      try {
        const params = {
          year: this.yearGraphic,
          program: this.program,
        }
        const data = await ReportsService.graphicVsPayment(params)

        this.authorize = data.data.authorize
        this.soft = data.data.soft
        this.optionDataX = data.data.labels
        this.contChart++
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    async clearSearchGraphic() {
      this.year = moment().format('YYYY')
      this.program = null
      await this.getValuesGraphic()
    },
    async search() {
      try {
        const params = {
          year: this.year,
          program: null,
        }
        const data = await ReportsService.getReportVsPayment(params)

        this.payments = data.data.data
        this.total_soft = data.data.total_soft
        this.total_authorized = data.data.total_authorized
        this.contTale++

        return this.payments
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    // eslint-disable-next-line consistent-return
    async getPrograms() {
      try {
        const data = await GlobalService.getPrograms()

        data.forEach(program => {
          this.optionPrograms.push({
            text: program.name,
            value: program.id,
          })
        })
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    async toogleReport() {
      this.isShowReport = !this.isShowReport
    },
    async updateReloj() {
      const reloj = moment(10 * 1 * 1000)
      const timer = setInterval(async () => {
        this.timer = reloj.format('mm:ss')
        reloj.subtract(1, 'seconds')
        if (this.timer === '00:00') {
          this.isTimerOn = true
          await this.search()

          clearInterval(timer)
        }
      }, 1000)
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async refreshData() {
      await this.updateReloj()
      this.isTimerOn = false
      try {
        const data = await ReportsService.generateVsPayment({
          year: this.year,
        })
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async exportToExcel() {
      try {
        const data = await ReportsService.generateVsPaymentExcel({
          year: this.year,
          program: this.program,
        })
        if (data.status === 200) {
          await this.forceFileDownload(data.data, 'vs_payment.xlsx')
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
  },
}
</script>

<style scoped>
.color_top{
  background: #636869 !important;
  opacity: 0.3!important;
}
.color_midle{
  background: rgb(99, 104, 105) !important;
  opacity: 0.4!important;
  color: white !important;
}
.color_total{
  background: black !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.width-w{
  width: 100% !important;
}
@media (max-width: 1444px) {
  .width-w{
    width: 88% !important;
  }
}
</style>
